<template>
<div class="share">
  <!-- 首页大图 -->
  <van-row class="bBox">
    <van-swipe class="my-swipe" :autoplay="8000" indicator-color="white">
      <van-swipe-item>
        <img @click="goEwm" width="100%" src="@/assets/img/share/banner4.jpg" />
      </van-swipe-item>
      <van-swipe-item>
        <img @click="goEwm" src="@/assets/img/share/banner1.jpg" />
      </van-swipe-item>
      <van-swipe-item>
        <img @click="goEwm" src="@/assets/img/share/banner2.jpg" />
      </van-swipe-item>
      <van-swipe-item>
        <img @click="goEwm" src="@/assets/img/share/banner3.jpg" />
      </van-swipe-item>
    </van-swipe>
  </van-row>
  <van-row class="pBox" style="margin-top: 0px;">
    <img src="@/assets/img/share/banner-x.png" />
    <img class="wow slideInLeft" src="@/assets/img/share/p1.jpg" />
    <img class="wow slideInRight" src="@/assets/img/share/p2.jpg" />
    <img class="wow slideInLeft" src="@/assets/img/share/p3.jpg" />
    <img class="wow slideInRight" src="@/assets/img/share/p4.jpg" />
  </van-row>
  <van-row>
    <van-swipe :show-indicators="false" class="my-swipe" :autoplay="3000" indicator-color="white">
      <van-swipe-item>
        <img width="100%" src="@/assets/img/share/banner-4.jpg" />
      </van-swipe-item>
      <van-swipe-item>
        <img src="@/assets/img/share/banner-3.jpg" />
      </van-swipe-item>
      <van-swipe-item>
        <img src="@/assets/img/share/banner-2.jpg" />
      </van-swipe-item>
      <van-swipe-item>
        <img src="@/assets/img/share/banner-1.jpg" />
      </van-swipe-item>
    </van-swipe>
  </van-row>
  <van-row style="margin-top: -5px;margin-right: 4px;">
  <van-tree-select class="shanX" height="75vw" :items="items" :main-active-index.sync="active">
    <template #content>
      <img v-if="active === 0" src="@/assets/img/share/y5.jpg" />
      <img v-if="active === 1" src="@/assets/img/share/y2.jpg" />
      <img v-if="active === 2" src="@/assets/img/share/y3.jpg" />
      <img v-if="active === 3" src="@/assets/img/share/y4.jpg" />
      <img v-if="active === 4" src="@/assets/img/share/y1.jpg" />
      <img v-if="active === 5" src="@/assets/img/share/y6.jpg" />
    </template>
  </van-tree-select>
  </van-row>
  <van-row class="pBox2" style="padding: 50px 0;background: #fff">
    <van-grid :gutter="20" :column-num="2">
      <van-grid-item>
        <img class="wow bounceInDown bg-green animated" src="@/assets/img/share/q1.jpg" />
      </van-grid-item>
      <van-grid-item>
        <img class="wow bounceInDown bg-green animated" data-wow-delay="0.5s" src="@/assets/img/share/q2.jpg" />
      </van-grid-item>
      <van-grid-item>
        <img class="wow bounceInDown bg-green animated" data-wow-delay="0.8s" src="@/assets/img/share/q3.jpg" />
      </van-grid-item>
      <van-grid-item>
        <img class="wow bounceInDown bg-green animated" data-wow-delay="1s" src="@/assets/img/share/q4.jpg" />
      </van-grid-item>
    </van-grid>
  </van-row>
  <van-row style="background-color: #fff;">
    <van-grid :gutter="20" :column-num="1">
      <van-grid-item>
        <div style="background: #f5f7fa;width: 100%;text-align: center;padding-bottom: 30px;border-radius: 0px;">
          <div style="margin: 20px 0 10px 0;font-weight: bold;font-size: 28px;">合作案例</div>
          <div style="font-size: 12px;">来自全国各地的他们，都在与测绘玩家合作</div>
          <div style="margin:30px 20px;">
            <img class="wow flipInX bg-purple animated" style="border-radius: 0px;" src="@/assets/img/share/indexvideo.jpg" />
          </div>
          <div style="margin: 20px 0 10px 0;font-weight: bold;font-size: 24px;">
            超<span style="color: #188ffb;">1000家</span>合作伙伴
          </div>
          <div style="font-size: 12px;">测绘玩家将竭尽全力，利用现有资源，为合作伙伴助力。</div>
        </div>
      </van-grid-item>
    </van-grid>
  </van-row>
  <van-row class="zyBox" style="background: #fff;padding: 20px;">
    <van-grid :gutter="10" :column-num="6">
      <van-grid-item>
        <img class="wow rollIn bg-blue animated" src="@/assets/img/share/hzhb/1.jpg"/>
      </van-grid-item>
      <van-grid-item>
        <img class="wow bounceInDown bg-green animated" src="@/assets/img/share/hzhb/2.jpg"/>
      </van-grid-item>
      <van-grid-item>
        <img class="wow lightSpeedIn bg-purple animated" src="@/assets/img/share/hzhb/3.jpg"/>
      </van-grid-item>
      <van-grid-item>
        <img class="wow rollIn bg-yellow animated" src="@/assets/img/share/hzhb/4.jpg"/>
      </van-grid-item>
      <van-grid-item>
        <img class="wow pulse bg-red animated" src="@/assets/img/share/hzhb/5.jpg"/>
      </van-grid-item>
      <van-grid-item>
        <img class="wow bounceInLeft bg-green animated" src="@/assets/img/share/hzhb/6.jpg"/>
      </van-grid-item>
      <van-grid-item>
        <img class="wow flipInX bg-purple animated" src="@/assets/img/share/hzhb/7.jpg"/>
      </van-grid-item>
      <van-grid-item>
        <img class="wow bounceInRight bg-yellow animated" src="@/assets/img/share/hzhb/8.jpg"/>
      </van-grid-item>
      <van-grid-item>
        <img class="wow rollIn bg-blue animated" src="@/assets/img/share/hzhb/9.jpg"/>
      </van-grid-item>
      <van-grid-item>
        <img class="wow shake bg-red animated" src="@/assets/img/share/hzhb/10.jpg"/>
      </van-grid-item>
      <van-grid-item>
        <img class="wow swing bg-purple animated" src="@/assets/img/share/hzhb/11.jpg"/>
      </van-grid-item>
      <van-grid-item>
        <img class="wow rollIn bg-red animated" src="@/assets/img/share/hzhb/12.jpg"/>
      </van-grid-item>
      <van-grid-item>
        <img class="wow bounceInU bg-yellow animated" src="@/assets/img/share/hzhb/13.jpg"/>
      </van-grid-item>
      <van-grid-item>
        <img class="wow lightSpeedIn bg-green animated" src="@/assets/img/share/hzhb/14.jpg"/>
      </van-grid-item>
      <van-grid-item>
        <img class="wow bounceInLeft bg-purple animated" src="@/assets/img/share/hzhb/15.jpg"/>
      </van-grid-item>
      <van-grid-item>
        <img class="wow pulse bg-blue animated" src="@/assets/img/share/hzhb/16.jpg"/>
      </van-grid-item>
      <van-grid-item>
        <img class="wow lightSpeedIn bg-yellow animated" src="@/assets/img/share/hzhb/17.jpg"/>
      </van-grid-item>
      <van-grid-item>
        <img class="wow bounce bg-green animated" src="@/assets/img/share/hzhb/18.jpg"/>
      </van-grid-item>
    </van-grid>
  </van-row>
  <van-row>
    <img class="wow bounceInUp bg-red animated" style="width: 100%" src="@/assets/img/share/db.png">
  </van-row>
  <van-row>
    <div id="ewm" style="text-align: center;background-color: #383b48;margin:-10px 0px;padding: 40px;">
      <span style="color: #fff;">欢迎咨询</span>
      <hr style="margin-top: 20px;margin-bottom: 35px;border: 0;border-top: 1px solid #939393;" />
    <img style="width: 100%" src="@/assets/img/share/ewm.jpg">
      &nbsp;
      <hr style="margin-top: -15px;margin-bottom: 1.5px;border: 0;border-top: 1px solid #939393;" />
    </div>
  </van-row>





</div>
</template>

<script>

import wx from 'weixin-js-sdk'; // 引入wxjs
import api from "@/utils/api";// 分享的接口  传给后端一个地址(与域名一致)

export default {
  name: "Share",
  data() {
    return {
      active: 0,
      items: [{ text: <van-icon name="cluster-o" ><span> 推广支持</span></van-icon> }, { text: <van-icon name="chart-trending-o" > 运营支持</van-icon> }, { text: <van-icon name="friends-o" > 培训支持</van-icon> }, { text: <van-icon name="logistics" > 物料支持</van-icon> }, { text: <van-icon name="qr" > 产品支持</van-icon> }, { text: <van-icon name="service-o" > 服务支持</van-icon> }],
    };
  },
  mounted() {
    let this_=this
    this.$nextTick(() => {
      setTimeout(function() {
        this_.$wow.init();
        this_.getShareInfo(); // 调用分享的事件
      }, 1000);
    });

  },
  methods:{
    goEwm(){
      this.$nextTick(()=>{
        window.scrollTo({top:document.getElementById('ewm').offsetTop - 138, behavior: 'smooth'})
        // 假如是指定的div内的内容滚动到指定位置
        document.querySelector('#searchBar').scrollTo({ top: document.getElementById('ewm').offsetTop, behavior: 'smooth' })
      })
    },
    // vue调用微信的自定义分享
    getShareInfo() {
      this.save = {
        // toUrl: location.href.split('#')[0] // 只需要传地址就好，其他后端都会写好来
        toUrl: 'http://maplays.net/#/share'
      };
      api.getShares(this.save).then(res => {
        // alert(JSON.stringify(res))
        const config = res.data;
        wx.config({
          appId: config.appId,  // appID 公众号的唯一标识
          timestamp: config.timestamp, // 生成签名的时间戳
          nonceStr: config.nonceStr, //  生成签名的随机串
          signature: config.signature, // 生成的签名
          jsApiList: [
            'updateAppMessageShareData',
            'updateTimelineShareData',
            'onMenuShareTimeline',
            'onMenuShareAppMessage',
            'onMenuShareQQ',
            'onMenuShareWeibo',
            'onMenuShareQZone',
          ]
        });

        wx.ready(() => {
          var shareData = {
            title: '测绘玩家-招募中',
            desc: '测绘玩家合作伙伴火热招募中，共创共赢 让创业更简单，全渠道企业服务平台',
            link: window.location.href,
            imgUrl: 'http://wychyq.cn/cors/wechat/maplays-logo.jpg'
          };
          //点击要去分享
          wx.updateAppMessageShareData(shareData);
          wx.updateTimelineShareData(shareData);
          wx.onMenuShareTimeline(shareData);
          wx.onMenuShareAppMessage(shareData);
          wx.onMenuShareQQ(shareData);
          wx.onMenuShareWeibo(shareData);
          wx.onMenuShareQZone(shareData);
        });
      });
    },

  },
}


//微信分享
// $.ajax({
//   url: "/weiXinFenXiang/fenxian",
//   type: "post",
//   dataType: 'json', //这个是接收服务器的格式！
//   async: false,
//   success: function (sj) {
//     wxData={
//       debug: true, // 是否开启调试模式
//       appId: sj.appId,
//       timestamp: sj.timestamp, //时间戳
//       nonceStr: sj.nonceStr, // 随机字符串
//       signature: sj.signature,
//       jsApiList: [
//         'updateAppMessageShareData',
//         'updateTimelineShareData',
//         // 微信新版1.4.0暂不支持安卓，故这里要两个接口一起调用下
//         'onMenuShareAppMessage',
//         'onMenuShareTimeline'
//       ] // 需要使用的JS接口列表
//     }
//
//
//     wx.config(wxData);
//
//     wx.ready(function () {
//       // 分享到朋友圈
//       // wx.onMenuShareTimeline({
//       //     title: 'CORS_24H自助购号', // 分享标题
//       //     link: 'http://www.corszh.com/gzhweb/index.html', // 分享链接
//       //     imgUrl: 'http://www.corszh.com/gzhweb/images/qxauto.jpg', // 分享图标
//       //     success: function () {
//       //         //alert('分享成功');
//       //     },
//       //     cancel: function () {
//       //         //alert('取消了分享');
//       //     }
//       // });
//
//       // 分享到朋友
//       wx.updateAppMessageShareData({
//         title: '七月特惠', // 分享标题
//         desc: '七月特惠爽歪歪活动来了！', // 分享描述
//         link: 'http://www.corszh.com/gzhweb/index.html', // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
//         imgUrl: 'http://www.corszh.com/gzhweb/promotions/libao1.png', // 分享图标
//         success: function () {
//         }
//       })
//
//       // 分享到朋友
//       // wx.onMenuShareAppMessage({
//       //     title: '七月特惠2', // 分享标题
//       //     desc: '七月特惠爽歪歪活动来了！', // 分享描述
//       //     link: 'http://www.corszh.com/gzhweb/promotions/indexJuly.html', // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
//       //     imgUrl: 'http://www.corszh.com/gzhweb/promotions/libao1.png', // 分享图标
//       //     type: 'link',
//       //     trigger: function (res) {
//       //         alert('用户点击发送给朋友');
//       //     },
//       //     success: function (res) {
//       //         alert('已分享...');
//       //     },
//       //     cancel: function (res) {
//       //         alert('已取消');
//       //     },
//       //     fail: function (res) {
//       //         alert(JSON.stringify(res));
//       //     }
//       // });
//     });
//
//
//
//
//
//   }
// });

</script>

<style lang="less" scoped>
.share{
  padding: 0 0;
  margin: 0 0;
  .bBox img{
  }
  img{
    width: 100%;
  }
  .pBox img{
    margin-top: -20px;
  }

  .pBox2 img{
    border-radius: 5px;
  }
  ::v-deep.van-grid-item__content{
    padding: 0 0;
  }
  ::v-deep[class*=van-hairline]::after{
    border: none;
  }
  ::v-deep.van-sidebar-item--select::before{
    //background-color: #00a6ff;
    background-color: #fff;
  }
  ::v-deep.van-sidebar-item--select{
    //font-size: 18px;
  }
  .zyBox img{
    width: 100%;
  }

}
</style>